import React, { useState} from 'react';
import './App.css';
import Board from "./Board";
import MoveSteps from "./MoveList";






function calculateWinner(squares: Array<any | string>,squareColor:Array<string>,setSquareColors:Function) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
    ];
    // set all to white first
    let newSquareColor=Array(squareColor.length).fill("white");
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            // alert ("return "+squares[a] )
            // return squares[a];
            //update background colors when winning
            // another solution would be return the detailed cells and handle later. not so good as this one
            newSquareColor[a]=newSquareColor[b]=newSquareColor[c]="yellow"
            setSquareColors(newSquareColor)
            return "winner is "+  [squares[a],'with [', a,b,c ,']'];
        }
    }
    //update always
    setSquareColors(newSquareColor)


    return null;
}

// interface historyData{
//     status:string[],
//     rowId: number,
//     colId:number,
//     player:string
// }



function Game() {
    // pull to higher level
    const [currentMove, setCurrentMove] = useState(0);
    const [xIsNext, setXIsNext] = useState(true);
    // todo: dynamically generate useState? use [] alone
    //
    const [history, setHistory] = useState([Array(9).fill(null)]);
    // rowid, colid and player
    const [historyStep,setHistoryStep]=useState([[-1,-1,""]])
    const [, setSquares] = useState(Array<any | string>(9).fill(null));
    const [squareColors,setSquareColors]=useState(Array(9).fill("white"))
    const [status, setStatus] = useState("")
    const [winner, setWinner] = useState<string|null>(null)
    //const [winner, setWinner] = useState([""])

    const [ascendOrder,setAscendOrder]=useState(true)
    const currentSquares = history[currentMove]

    // handle history and winner checks
    //this is an action belong to Board. but history,XIsNext is shared with MoveList
    function handlePlay(nextSquares: any[] | string[], rowId:number,colId:number,player:string ) {
        //slice the history , then append nextSquare as the new history
        const nextHistory = [...history.slice(0, currentMove + 1), nextSquares];
        const nextHistoryStep= [...historyStep.slice(0, currentMove + 1), [rowId,colId,player]];

        setHistory(nextHistory);
        setHistoryStep(nextHistoryStep);

        setCurrentMove(nextHistory.length - 1);
        // setHistory([...history, nextSquares]);
        setSquares(nextSquares);

        //note: squareStats may not updated righnow, here use nextSquare
        const nextWinner = calculateWinner(nextSquares,squareColors, setSquareColors)

        if (nextWinner) {
            setStatus(nextWinner)
            setWinner(nextWinner)
        } else {
            setXIsNext(!xIsNext)
            setStatus("Next player: " + (xIsNext ? "X" : "O"));
        }

        return nextWinner
    }


    // this handles what to do after click the clickable list
    //this is an action belong to MoveList. but XIsNext is shared with board and MoveList so placed here
    function jumpTo(nextMove: number) {
        setCurrentMove(nextMove);
        setXIsNext(nextMove % 2 === 0);
        const nextWinner=calculateWinner(history[nextMove],squareColors,setSquareColors)
        if (nextWinner) {
            setStatus(nextWinner)
            setWinner(nextWinner)
        } else {
            setStatus("Next player: " + (xIsNext ? "X" : "O"));
            setWinner(null)
        }
    }


    function toggleOrder()
    {
        // alert("toggle clicked , ascendOrder now is " + ascendOrder)
        // rever the sign of ascendOrder
        setAscendOrder(!ascendOrder);
    }

    return (
        <div className="game">
            <div className="game-board">
                <Board xIsNext={xIsNext} squares={currentSquares} onPlay={handlePlay} winner={winner} squareColors={squareColors} />
            </div>
            <div className="game-info">

                <MoveSteps currentMove={currentMove} history={history} historyStep={historyStep} status={status} jumpTo={jumpTo}
                           toggleOrder={toggleOrder} ascendOrder={ascendOrder}/>
            </div>


        </div>
    );
}


export default Game