import React from "react";
import Square from './Square';
import './Board.css'

interface boardData {
    //attributes
    xIsNext: boolean,
    winner:string|null,
    // data
    squares: Array<any | string>
    squareColors:Array<string>
    // handler
    //todo: change function to more clear input and output definisitons
    onPlay: Function
}


// 3*3 square object
function Board({xIsNext, squares,squareColors, onPlay, winner}: boardData) {

    // handle after click updates
    function handleClick(i: number) {
        // duplicate detection and game end detection
        if (winner!=null||squares[i] ) {
            return
        }

        //make a shallow copy of the squarestate object to avoid direct change
        const nextSquares = squares.slice();
        if (xIsNext) {
            nextSquares[i] = "O";
        } else {
            nextSquares[i] = "X";
        }

        //another solution: pass the winning cells back and ...
        // now: update the color list of winning cells directly
        onPlay(nextSquares,Math.floor(i/3)+1,i%3+1,xIsNext?"O":"X")

        // if (result )
        // {
        //     // handle highlight of winning cells
        //     var element = document.getElementById("myElement");
        //     alert(element)
        //     //! means if not null
        //     element!.style.backgroundColor = "yellow";
        // }
        //todo: add check if all squares are filled
    }

    // make 2 loops
    // embed in return statement, use "let" will result in empty object but const is ok,  useState may trigger re-redering, lift may cause problem with handleClick()
    //here Array(3) generate an array with 3 null objects. this will be automatically skipped . fill(0) can proceed into next step
    //NOTE: Array()=>{} automatically generate list()
    const squareBox = Array(3).fill(0).map((element, xIndex, array) => {
        // alert("triggered");
        // console.log(array); // [empty x 3]
        // console.log(xIndex);
        // console.log(element);
        let aRowObject = Array(3).fill(0).map((element, yIndex, array) => {
            // console.log(xIndex * 3 + yIndex);
            const id = xIndex * 3 + yIndex
            // here use className so can set style for lateral layout list
            return (<li key={id} className="square-row">
                    {<Square value={squares[id]} onSquareClick={() => handleClick(id)} color={squareColors[id]}/>}
                </li>
            );
        });


        return (
            <div className="board-row">
                <ol key={xIndex}>
                    {aRowObject}
                </ol>
            </div>
        );
    });


    // each square will need to know which cell to update and inject a handle function todo: why inject handler function?
    return (
        <>
            <ol>
                {squareBox}
            </ol>

            {/*<div className="board-row">*/}
            {/*    <Square value={squares[0]} onSquareClick={() => handleClick(0)}/>*/}
            {/*    <Square value={squares[1]} onSquareClick={() => handleClick(1)}/>*/}
            {/*    <Square value={squares[2]} onSquareClick={() => handleClick(2)}/>*/}
            {/*</div>*/}
            {/*<div className="board-row">*/}
            {/*    <Square value={squares[3]} onSquareClick={() => handleClick(3)}/>*/}
            {/*    <Square value={squares[4]} onSquareClick={() => handleClick(4)}/>*/}
            {/*    <Square value={squares[5]} onSquareClick={() => handleClick(5)}/>*/}
            {/*</div>*/}
            {/*<div className="board-row">*/}
            {/*    <Square value={squares[6]} onSquareClick={() => handleClick(6)}/>*/}
            {/*    <Square value={squares[7]} onSquareClick={() => handleClick(7)}/>*/}
            {/*    <Square value={squares[8]} onSquareClick={() => handleClick(8)}/>*/}
            {/*</div>*/}
        </>);
}

export default Board