import React from "react";


type MoveData=
{
    history: string[][],
    historyStep:(string|number)[][],
    currentMove: number,
    status: string,
    toggleOrder: Function,
    jumpTo: Function,
    ascendOrder:boolean

}


function MoveList({history, historyStep, currentMove,status,toggleOrder,jumpTo,ascendOrder}:MoveData) {

    //this is creating a clickable list, will refresh after every click
    // the list is actually lookping over history
    //what to do after the click is handled in jumpTo()
    // originally is const moves = . since we need to change moves in toggleOrder(), make the moves a setState()

    const orderedHist=(ascendOrder ? history : [...history].reverse())
    const orderedHistSteps=(ascendOrder ? historyStep : [...historyStep].reverse())

    // alert("AscendOrder : "   + ascendOrder + "Order History : " + orderedHist)
    const moveSteps = orderedHist.map((historyList, move) => {
        let description;
        let playStatus;
        if (move > 0) {
            const rowId= orderedHistSteps[move][0]
            const colId=orderedHistSteps[move][1]
            const player=orderedHistSteps[move][2]
            description = 'Go to move #' + move ;
            playStatus= player +  " at Row: " + rowId + " Col: " + colId
        } else {
            description = 'Go to game start';
        }
        //if move is at the current move step, then show you are the move # instead of a button
        return (
            <div>
                <li key={move}>
                    {move === currentMove
                        ? "you are at move #" + move +" : "+ playStatus :
                        <button onClick={() => jumpTo(move)}>{description}</button>}
                </li>
            </div>
        );
    });

    return (
        <>
            {/*status title*/}
            <div className="status">{status}</div>
            {/*reverse order button*/}
            <div>
                <button onClick={()=>toggleOrder()}>Click to reverse order</button>
            </div>
            {/*detailed move list*/}
            {ascendOrder ? moveSteps : [...moveSteps].reverse()}
        </>
    )
}

export default MoveList
