// single square object
import React, {MouseEventHandler} from "react";
import './Square.css'

interface squareData {
    //attribute
    //data
    value: number
    color:string
    //handler
    onSquareClick: MouseEventHandler<any>
}

function Square({value, color, onSquareClick}: squareData) {
    // simply return a button with clicks injected from elsewhere
    // note the style of setting background color
    return <button
        className="square"
        style={{backgroundColor:color}}
        onClick={onSquareClick}>
        {value}
    </button>
}

export default Square